import * as React from "react";
import { Link } from "gatsby";
import Helmet from "react-helmet";

const NotFoundPage = () => {
  return (
    <>
      <Helmet htmlAttributes={{ lang: "ru" }}>
        <title>Страница не найдена</title>
        <meta name="description" content="" />
      </Helmet>
      <main>
        <h1>Страница не найдена</h1>
        <Link to="/">Вернуться на главную</Link>
      </main>
    </>
  );
};

export default NotFoundPage;
